import {useEffect, useRef, useState} from "react";
import {TextLinkButton} from "./Button";

export const NodesMap = ({}) => {
    const nodeMap = useRef();
    const [data, setData] = useState({});
   const fetchNodes = async () => {
        const response = await fetch('https://theta-mkt-api.thetatoken.org/v1/nodes/locations/optimized');
        const data = await response.json();
        return data;
   }

    useEffect( () => {
        fetchNodes().then((data) => {
            setData(data);
            nodeMap.current.contentWindow.postMessage(
                {
                    type: "populateMapOptimized",
                    data: data
                }, '*');
        });
    }, []);

    return (
        <div className={'NodesMap'}>
            <iframe id='nodes-map'
                    ref={nodeMap}
                    title={'nodes map'}
                    src={'/map.html'}
            />
            <div className={'NodesMapLegend'}>
                <div className={'NodesMapLegendItem NodesMapLegendItem--en'}>
                    <a className={'NodesMapLegendItem__title'}>
                        {`Active Edge Node (${data?.totals?.en || '-'})`}
                    </a>
                </div>
                <div className={'NodesMapLegendItem NodesMapLegendItem--gn'}>
                    <a className={'NodesMapLegendItem__title'}>
                        {`Guardian Node (${data?.totals?.gn || '-'})`}
                    </a>
                </div>
                <div className={'NodesMapLegendItem NodesMapLegendItem--vn'}>
                    <a className={'NodesMapLegendItem__title'}>
                        {`Validator Node (${data?.totals?.validator || '-'})`}
                    </a>
                </div>
            </div>
            <TextLinkButton className={'NewsSection__view-all-news'}
                            href={'/community'}
            >
                Join the Theta community
            </TextLinkButton>
        </div>
    );
}